import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#faf5f5", // main color
        secondary: "#e6e1e1", // background
        accent: "#611616", // top bar
        error: "#d61b15", // red
        icon: "#000000", //icons
        indie: "#e60000", //Indie
        AAA: "#00b300", //AAA
        wip: "#ffab1a", //work in progress
        ea: "#3399ff", //early access
        nsfw: "#ff80d5", //nsfw ribbon
        scrollbar: "#d61b15", //scollbar
      },
      dark: {
        primary: "#faf5f5", // main color
        secondary: "#080618", // background
        accent: "#0f0e14", // top bar
        error: "#171147", // red
        indie: "#800000", //Indie
        AAA: "#004d00", //AAA        
        icon: "#ffffff", //icons
        wip: "#cc8100", //work in progress
        ea: "#004d99", //early access
        nsfw: "#cc0088", //nsfw ribbon
        scrollbar: "#171147", //scrollbar   
      },
    },
  },
});
