export default {
  steam: "mdi-steam",
  xbox: "mdi-microsoft-xbox",
  playstation: "mdi-sony-playstation",
  switch: "mdi-nintendo-switch",
  ios: "mdi-apple",
  android: "mdi-android",
  /* itchio: "mdi-microsoft-xbox", */
  /* web: "mdi-earth", */
};
